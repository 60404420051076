/** @jsx jsx */
import { jsx, Box, Grid, Container, Styled} from 'theme-ui'
import {Link} from 'gatsby'

import SEO from "../components/seo"

const BreedGroups = ({data}) => {

  const breeds = data.breeds.edges


  return(
    <section>
      <SEO title="Breed Groups" />
      <Container sx={{px: [5,5,0,0]}}>
        <div sx={{px: 5, textAlign: "center", mt: 6}}>
          <Styled.h1>Breed Groups</Styled.h1>
          <Styled.p>Here is a list of all of the AKC breed groups.</Styled.p>
        </div>

        <Grid columns={[1,1,3,4]}>
          {breeds.map(({node,i}) => (
            <Box sx={{p: 5, boxShadow: 'regular'}}>
              <Styled.a as={Link} to={`/breed-groups/${node.slug.current}`}>{node.title}</Styled.a>
            </Box>
          ))}
      
        </Grid>
      </Container>
    </section>
  )
}

export default BreedGroups

export const query = graphql`
  query {
    breeds: allSanityBreedGroup {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  } 
`